// third-party
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// project import
import menu from "./menu";
import snackbar from "./snackbar";
import cartReducer from "./cart";
import filter from "./filter";
import auth from "./login";
import employerConfigReducer from "./employerConfig";

import { employerApi } from "./employer";
import { moduleApi } from "./modules";
import { rolesApi } from "./roles";
import { adminApi } from "./admin-user";
import { employeeApi } from "./employee";
import { schemeTypeApi } from "./schemeType";
import { providerApi } from "./provider";
import { notificationApi } from "./notification";
import { investmentOptionApi } from "./investmentOptions";
import { productMasterApi } from "./product-master";
import { pensionFundApi } from "./pensionFunds";
import { supplierApi } from "./suppliers";
import { avcPlanApi } from "./avcPlans";
import { pensionTypeApi } from "./pensionType";
import { payPeriodAPI } from "./payPeriod";
import { schemeApi } from "./scheme";
import { masterDataChangelogApi } from "./changelog";
import { imsWebminarApi } from "./ims-webinar";
import { cmsApi } from "./cms-pages";
import { journeyApi } from "./journey";
import { responsesApi } from "./responses";
import { consentApi } from "./consent";
import { smartEngineProductAPI } from "./smartEngineProduct";
import { ruleMasterApi } from "./ruleMaster"
import { seminarApi } from "./seminar";

// ==============================|| COMBINE REDUCERS ||============================== //
const reducers = combineReducers({
  auth,
  menu,
  snackbar,
  cart: persistReducer(
    {
      key: "cart",
      storage,
      keyPrefix: "mantis-ts-",
    },
    cartReducer
  ),
  filter,
  employerConfig: employerConfigReducer,
  [employerApi.reducerPath]: employerApi.reducer,
  [moduleApi.reducerPath]: moduleApi.reducer,
  [rolesApi.reducerPath]: rolesApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
  [employeeApi.reducerPath]: employeeApi.reducer,
  [schemeTypeApi.reducerPath]: schemeTypeApi.reducer,
  [providerApi.reducerPath]: providerApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
  [investmentOptionApi.reducerPath]: investmentOptionApi.reducer,
  [productMasterApi.reducerPath]: productMasterApi.reducer,
  [pensionFundApi.reducerPath]: pensionFundApi.reducer,
  [supplierApi.reducerPath]: supplierApi.reducer,
  [avcPlanApi.reducerPath]: avcPlanApi.reducer,
  [pensionTypeApi.reducerPath]: pensionTypeApi.reducer,
  [schemeApi.reducerPath]: schemeApi.reducer,
  [payPeriodAPI.reducerPath]: payPeriodAPI.reducer,
  [masterDataChangelogApi.reducerPath]: masterDataChangelogApi.reducer,
  [imsWebminarApi.reducerPath]: imsWebminarApi.reducer,
  [seminarApi.reducerPath]: seminarApi.reducer,
  [cmsApi.reducerPath]: cmsApi.reducer,
  [journeyApi.reducerPath]: journeyApi.reducer,
  [consentApi.reducerPath]: consentApi.reducer,
  [responsesApi.reducerPath]: responsesApi.reducer,
  [smartEngineProductAPI.reducerPath]: smartEngineProductAPI.reducer,
  [ruleMasterApi.reducerPath]: ruleMasterApi.reducer
});

export default reducers;
