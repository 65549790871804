import { lazy } from "react";

// project import
import MainLayout from "layout/MainLayout";
import CommonLayout from "layout/CommonLayout";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import PermissionGuard from "utils/route-guard/PermissionGuard";
import AddSequence from "pages/health-checkup/smart-engine-product/AddSequence";
import UpdateSequence from "pages/health-checkup/smart-engine-product/UpdateSequence";

// render - LayoutContainer
const LayoutContainer = Loadable(lazy(() => import("pages/LayoutContainer")));

// render - dashboard
const DashboardDefault = Loadable(
  lazy(() => import("pages/dashboard/default"))
);
const EmailUnsubscribe = Loadable(
  lazy(() => import("pages/forms/EmailUnsubscribe"))
);
//render - employer
const EmployerList = Loadable(lazy(() => import("pages/employer/Employer")));
const EmployerListInfo = Loadable(
  lazy(() => import("pages/employer/Employer"))
);
const AddEmployer = Loadable(lazy(() => import("pages/employer/AddEmployer")));
const ViewEmployer = Loadable(
  lazy(() => import("pages/employer/ViewEmployer"))
);
const EditEmployer = Loadable(
  lazy(() => import("pages/employer/EditEmployer"))
);
//render - roles
const Roles = Loadable(lazy(() => import("pages/roles/Roles")));
const AddRole = Loadable(lazy(() => import("pages/roles/AddRole")));
const ViewRole = Loadable(lazy(() => import("pages/roles/ViewRole")));
const EditRole = Loadable(lazy(() => import("pages/roles/EditRole")));
//render - admin users
const AdminUsers = Loadable(lazy(() => import("pages/admin-users/AdminUser")));
const AddAdminUser = Loadable(
  lazy(() => import("pages/admin-users/AddAdminUsers"))
);
const EditAdminUser = Loadable(
  lazy(() => import("pages/admin-users/EditAdminUsers"))
);
const ViewAdminUser = Loadable(
  lazy(() => import("pages/admin-users/ViewAdminUser"))
);

//render - employees
const Employees = Loadable(lazy(() => import("pages/employees/Employees")));
const AddEmployee = Loadable(lazy(() => import("pages/employees/AddEmployee")));
const EditEmployee = Loadable(
  lazy(() => import("pages/employees/EditEmployee"))
);
const ManageEmployeeRoles = Loadable(
  lazy(() => import("pages/employees/ManageEmployeeRoles"))
);
const ViewEmployee = Loadable(
  lazy(() => import("pages/employees/ViewEmployee"))
);

//render - Product Master
const ProductMaster = Loadable(
  lazy(() => import("pages/product-master/ProductMaster"))
);

const EditProductMaster = Loadable(
  lazy(() => import("pages/product-master/EditProductMaster"))
);

const ManageEmployersForProduct = Loadable(
  lazy(() => import("pages/product-master/ManageEmployersForProduct"))
);

//render - Ims & Webinars
const ImsWebinars = Loadable(
  lazy(() => import("pages/ims-webinars/ImsWebinars"))
);

const AddImsWebinars = Loadable(
  lazy(() => import("pages/ims-webinars/AddImsWebinars"))
);
const ViewImsWebinars = Loadable(
  lazy(() => import("pages/ims-webinars/ViewImsWebinars"))
);
const DuplicateImsWebinars = Loadable(
  lazy(() => import("pages/ims-webinars/DuplicateWebinar"))
);

const EditImsWebinars = Loadable(
  lazy(() => import("pages/ims-webinars/EditImsWebminar"))
);

//render - Seminars

const Seminars = Loadable(
  lazy(() => import("pages/seminars/Seminars"))
);

const AddSeminars = Loadable(
  lazy(() => import("pages/seminars/AddSeminars"))
);
const ViewSeminars= Loadable(
  lazy(() => import("pages/seminars/ViewSeminars"))
);

const EditSeminars = Loadable(
  lazy(() => import("pages/seminars/EditSeminar"))
);

//render - CMS pages
const CmsPages = Loadable(lazy(() => import("pages/cms-pages/CmsPagesList")));

const ProductPages = Loadable(
  lazy(() => import("pages/cms-pages/ProductPageList"))
);

//render - masterEntity
const NotificationList = Loadable(
  lazy(() => import("pages/notifications/Notifications"))
);

//render - masterEntity
const SchemeTypeContainer = Loadable(
  lazy(() => import("pages/masterEntity/schemeType"))
);
const SchemeTypes = Loadable(
  lazy(() => import("pages/masterEntity/schemeType/SchemeTypes"))
);
const ViewScheme = Loadable(
  lazy(() => import("pages/masterEntity/schemeType/ViewScheme"))
);

const SchemeContainer = Loadable(
  lazy(() => import("pages/masterEntity/scheme"))
);
const Schemes = Loadable(
  lazy(() => import("pages/masterEntity/scheme/Schemes"))
);
const AddScheme = Loadable(
  lazy(() => import("pages/masterEntity/scheme/AddScheme"))
);
const EditScheme = Loadable(
  lazy(() => import("pages/masterEntity/scheme/EditScheme"))
);
const ViewSchemeDetail = Loadable(
  lazy(() => import("pages/masterEntity/scheme/ViewScheme"))
);

//render - masterEntity - providers
const ProviderContainer = Loadable(
  lazy(() => import("pages/masterEntity/providers"))
);
const Providers = Loadable(
  lazy(() => import("pages/masterEntity/providers/Providers"))
);
const AddProvider = Loadable(
  lazy(() => import("pages/masterEntity/providers/AddProvider"))
);
const ViewProvider = Loadable(
  lazy(() => import("pages/masterEntity/providers/ViewProvider"))
);
const EditProvider = Loadable(
  lazy(() => import("pages/masterEntity/providers/EditProvider"))
);

//render - masterEntity - InvestmentOption
const InvestmentOptionContainer = Loadable(
  lazy(() => import("pages/masterEntity/investmentOptions"))
);
const InvestmentOption = Loadable(
  lazy(() => import("pages/masterEntity/investmentOptions/InvestmentOptions"))
);
const AddInvestmentOption = Loadable(
  lazy(() => import("pages/masterEntity/investmentOptions/AddInvestmentOption"))
);
const ViewInvestmentOption = Loadable(
  lazy(
    () => import("pages/masterEntity/investmentOptions/ViewInvestmentOption")
  )
);
const EditInvestmentOption = Loadable(
  lazy(
    () => import("pages/masterEntity/investmentOptions/EditInvestmentOption")
  )
);

//render - masterEntity - PensionFund
const PensionFundContainer = Loadable(
  lazy(() => import("pages/masterEntity/pensionFund"))
);
const PensionFunds = Loadable(
  lazy(() => import("pages/masterEntity/pensionFund/PensionFunds"))
);
const ViewPensionFund = Loadable(
  lazy(() => import("pages/masterEntity/pensionFund/ViewPensionFund"))
);
const AddPensionFund = Loadable(
  lazy(() => import("pages/masterEntity/pensionFund/AddPensionFund"))
);
const EditPensionFund = Loadable(
  lazy(() => import("pages/masterEntity/pensionFund/EditPensionFund"))
);
//render - masterEntity - Avc Plan
const AvcPlans = Loadable(
  lazy(() => import("pages/masterEntity/avcPlans/AvcPlans"))
);
const ViewAvcPlans = Loadable(
  lazy(() => import("pages/masterEntity/avcPlans/ViewAvcPlans"))
);
//render - masterEntity - Suppliers
const AvcPlansContainer = Loadable(
  lazy(() => import("pages/masterEntity/avcPlans"))
);
//render - masterEntity - Suppliers
const SupplierContainer = Loadable(
  lazy(() => import("pages/masterEntity/suppliers"))
);
const Suppliers = Loadable(
  lazy(() => import("pages/masterEntity/suppliers/Suppliers"))
);
const ViewSupplier = Loadable(
  lazy(() => import("pages/masterEntity/suppliers/ViewSuppliers"))
);
const AddSupplier = Loadable(
  lazy(() => import("pages/masterEntity/suppliers/AddSuppliers"))
);
const EditSupplier = Loadable(
  lazy(() => import("pages/masterEntity/suppliers/EditSuppliers"))
);

//render - masterEntity - PensionType
const PensionTypeContainer = Loadable(
  lazy(() => import("pages/masterEntity/pensionType"))
);
const PensionTypes = Loadable(
  lazy(() => import("pages/masterEntity/pensionType/PensionTypes"))
);
const ViewPensionType = Loadable(
  lazy(() => import("pages/masterEntity/pensionType/ViewPensionType"))
);
const AddPensionType = Loadable(
  lazy(() => import("pages/masterEntity/pensionType/AddPensionType"))
);
const EditPensionType = Loadable(
  lazy(() => import("pages/masterEntity/pensionType/EditPensionType"))
);

//render - masterEntity - ruleMaster
const RuleMasterContainer = Loadable(
  lazy(() => import("pages/masterEntity/rule-master"))
);
const RulesMaster = Loadable(
  lazy(() => import("pages/masterEntity/rule-master/Rules"))
);
const AddRule = Loadable(
  lazy(() => import("pages/masterEntity/rule-master/AddRule"))
);
const ViewRule = Loadable(
  lazy(() => import("pages/masterEntity/rule-master/ViewRule"))
);
const EditRule = Loadable(
  lazy(() => import("pages/masterEntity/rule-master/EditRule"))
);

//render - change log
const ChangeLog = Loadable(lazy(() => import("pages/change-log/index")));

const ViewChangeLog = Loadable(
  lazy(() => import("pages/change-log/ViewChangeLog"))
);

// rendwer - masterEntity - PayPeriod
const PayPeriodContainer = Loadable(
  lazy(() => import("pages/masterEntity/payPeriod"))
);
const PayPeriod = Loadable(
  lazy(() => import("pages/masterEntity/payPeriod/PayPeriod"))
);
const ViewPayPeriodDetails = Loadable(
  lazy(() => import("pages/masterEntity/payPeriod/ViewPayPeriodDetails"))
);
const AddPayPeriod = Loadable(
  lazy(() => import("pages/masterEntity/payPeriod/AddPayPeriod"))
);
const EditPayPeriod = Loadable(
  lazy(() => import("pages/masterEntity/payPeriod/EditPayPeriod"))
);

// render - financial health checkup > journry
const JourneyList = Loadable(
  lazy(() => import("pages/health-checkup/journey/JourneyList"))
);
const ViewJourney = Loadable(
  lazy(() => import("pages/health-checkup/journey/ViewJourney"))
);
const AddJourney = Loadable(
  lazy(() => import("pages/health-checkup/journey/AddJourney"))
);
const EditJourney = Loadable(
  lazy(() => import("pages/health-checkup/journey/EditJourney"))
);

// render - financial health checkup > responses
const ResponsesList = Loadable(
  lazy(() => import("pages/health-checkup/responses/ResponsesList"))
);
const ViewResponses = Loadable(
  lazy(() => import("pages/health-checkup/responses/ViewResponses"))
);

// render - financial health checkup > consent
const HealthConsent = Loadable(
  lazy(() => import("pages/health-checkup/consent"))
);

const SmartEngineProductList = Loadable(
  lazy(
    () =>
      import(
        "pages/health-checkup/smart-engine-product/SmartEngineProductsList"
      )
  )
);

// render - applications
const UserProfile = Loadable(lazy(() => import("pages/apps/profiles/user")));

const MaintenanceError = Loadable(lazy(() => import("pages/maintenance/404")));
const MaintenanceError500 = Loadable(
  lazy(() => import("pages/maintenance/500"))
);

const MaintenanceComingSoon = Loadable(
  lazy(() => import("pages/maintenance/coming-soon"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "/",
          children: [
            {
              path: "/",
              element: (
                <PermissionGuard
                  moduleName="Dashboard"
                  permissionType="CanView"
                >
                  <DashboardDefault />
                </PermissionGuard>
              ),
            },
          ],
        },
        {
          path: "notifications",
          element: <NotificationList />,
        },
        {
          path: "employer",
          children: [
            {
              path: "employerList",
              element: (
                <PermissionGuard
                  moduleName="Employers"
                  permissionType="CanView"
                >
                  <EmployerList />
                </PermissionGuard>
              ),
            },
            {
              path: "employerInfo",
              element: (
                <PermissionGuard
                  moduleName="Employers"
                  permissionType="CanView"
                >
                  <EmployerListInfo />
                </PermissionGuard>
              ),
            },
            {
              path: ":orgtypeid/add",
              element: (
                <PermissionGuard
                  moduleName="Employers"
                  permissionType="CanCreate"
                >
                  <AddEmployer />
                </PermissionGuard>
              ),
            },
            {
              path: "edit/:id",
              element: (
                <PermissionGuard
                  moduleName="Employers"
                  permissionType="CanEdit"
                >
                  <EditEmployer />
                </PermissionGuard>
              ),
            },
            {
              path: "view/:id",
              element: (
                <PermissionGuard
                  moduleName="Employers"
                  permissionType="CanView"
                >
                  <ViewEmployer />
                </PermissionGuard>
              ),
            },
          ],
        },
        {
          path: "role",
          children: [
            {
              path: "roles",
              element: (
                <PermissionGuard
                  moduleName="Roles & Permissions"
                  permissionType="CanView"
                >
                  <Roles />
                </PermissionGuard>
              ),
            },
            {
              path: "add-role",
              element: (
                <PermissionGuard
                  moduleName="Roles & Permissions"
                  permissionType="CanCreate"
                >
                  <AddRole />
                </PermissionGuard>
              ),
            },
            {
              path: "view-role/:id",
              element: (
                <PermissionGuard
                  moduleName="Roles & Permissions"
                  permissionType="CanView"
                >
                  <ViewRole />
                </PermissionGuard>
              ),
            },
            {
              path: "edit/:id",
              element: (
                <PermissionGuard
                  moduleName="Roles & Permissions"
                  permissionType="CanEdit"
                >
                  <EditRole />
                </PermissionGuard>
              ),
            },
          ],
        },
        {
          path: "admin-users",
          children: [
            {
              path: "users",
              element: (
                <PermissionGuard
                  moduleName="Admin Users"
                  permissionType="CanView"
                >
                  <AdminUsers />
                </PermissionGuard>
              ),
            },
            {
              path: "add-admin-user",
              element: (
                <PermissionGuard
                  moduleName="Admin Users"
                  permissionType="CanCreate"
                >
                  <AddAdminUser />
                </PermissionGuard>
              ),
            },
            {
              path: "view-admin-user/:id",
              element: (
                <PermissionGuard
                  moduleName="Admin Users"
                  permissionType="CanView"
                >
                  <ViewAdminUser />
                </PermissionGuard>
              ),
            },
            {
              path: "edit/:id",
              element: (
                <PermissionGuard
                  moduleName="Admin Users"
                  permissionType="CanEdit"
                >
                  <EditAdminUser />
                </PermissionGuard>
              ),
            },
          ],
        },
        {
          path: "employees",
          children: [
            {
              path: "employeesList",
              element: (
                <PermissionGuard
                  moduleName="Employees"
                  permissionType="CanView"
                >
                  <Employees />
                </PermissionGuard>
              ),
            },
            {
              path: "add",
              element: (
                <PermissionGuard
                  moduleName="Employees"
                  permissionType="CanCreate"
                >
                  <AddEmployee />
                </PermissionGuard>
              ),
            },
            {
              path: "edit/:id",
              element: (
                <PermissionGuard
                  moduleName="Employees"
                  permissionType="CanEdit"
                >
                  <EditEmployee />
                </PermissionGuard>
              ),
            },
            {
              path: "view/:id",
              element: (
                <PermissionGuard
                  moduleName="Employees"
                  permissionType="CanView"
                >
                  <ViewEmployee />
                </PermissionGuard>
              ),
            },
            {
              path: "manage-employee-roles/:id",
              element: (
                <PermissionGuard
                  moduleName="Employees"
                  permissionType="CanEdit"
                >
                  <ManageEmployeeRoles />
                </PermissionGuard>
              ),
            },
          ],
        },
        {
          path: "product-master",
          children: [
            {
              path: "list",
              element: (
                <PermissionGuard moduleName="Products" permissionType="CanView">
                  <ProductMaster />
                </PermissionGuard>
              ),
            },
            {
              path: "edit/:id",
              element: (
                <PermissionGuard moduleName="Products" permissionType="CanEdit">
                  <EditProductMaster />
                </PermissionGuard>
              ),
            },
            {
              path: "manage-employers/:id",
              element: (
                <PermissionGuard moduleName="Products" permissionType="CanEdit">
                  <ManageEmployersForProduct />
                </PermissionGuard>
              ),
            },
          ],
        },
        {
          path: "webinars",
          children: [
            {
              path: "list",
              element: (
                <PermissionGuard moduleName="Webinars" permissionType="CanView">
                  <ImsWebinars />
                </PermissionGuard>
              ),
            },
            {
              path: "add",
              element: (
                <PermissionGuard
                  moduleName="Webinars"
                  permissionType="CanCreate"
                >
                  <AddImsWebinars />
                </PermissionGuard>
              ),
            },
            {
              path: "edit-webinar/:id",
              element: (
                <PermissionGuard moduleName="Webinars" permissionType="CanEdit">
                  <EditImsWebinars />
                </PermissionGuard>
              ),
            },
            {
              path: "view-webinar/:id",
              element: (
                <PermissionGuard moduleName="Webinars" permissionType="CanView">
                  <ViewImsWebinars />
                </PermissionGuard>
              ),
            },
            {
              path: ":id",
              element: (
                <PermissionGuard moduleName="Webinars" permissionType="CanEdit">
                  <DuplicateImsWebinars />
                </PermissionGuard>
              ),
            },
          ],
        },
        {
          path: "seminars",
          children: [
            {
              path: "list",
              element: (
                <PermissionGuard moduleName="Webinars" permissionType="CanView">
                  <Seminars />
                </PermissionGuard>
              ),
            },
            {
              path: "add",
              element: (
                <PermissionGuard
                  moduleName="Webinars"
                  permissionType="CanCreate"
                >
                  <AddSeminars />
                </PermissionGuard>
              ),
            },
            {
              path: "edit-seminar/:id",
              element: (
                <PermissionGuard moduleName="Webinars" permissionType="CanEdit">
                  <EditSeminars />
                </PermissionGuard>
              ),
            },
            {
              path: "view-seminar/:id",
              element: (
                <PermissionGuard moduleName="Webinars" permissionType="CanView">
                  <ViewSeminars />
                </PermissionGuard>
              ),
            },
          ],
        },
        {
          path: "masterEntity",
          children: [
            {
              path: "schemeType",
              element: <SchemeTypeContainer />,
              children: [
                {
                  path: "list",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanView"
                    >
                      <SchemeTypes />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "view/:id",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanView"
                    >
                      <ViewScheme />
                    </PermissionGuard>
                  ),
                },
              ],
            },
            {
              path: "scheme",
              element: <SchemeContainer />,
              children: [
                {
                  path: "list",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanView"
                    >
                      <Schemes />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "view/:id",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanView"
                    >
                      <ViewSchemeDetail />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "create",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanCreate"
                    >
                      <AddScheme />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "edit/:id",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanEdit"
                    >
                      <EditScheme />
                    </PermissionGuard>
                  ),
                },
              ],
            },
            {
              path: "providers",
              element: <ProviderContainer />,
              children: [
                {
                  path: "list",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanView"
                    >
                      <Providers />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "create",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanCreate"
                    >
                      <AddProvider />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "view/:id",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanView"
                    >
                      <ViewProvider />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "edit/:id",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanEdit"
                    >
                      <EditProvider />
                    </PermissionGuard>
                  ),
                },
              ],
            },
            {
              path: "investmentoption",
              element: <InvestmentOptionContainer />,
              children: [
                {
                  path: "list",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanView"
                    >
                      <InvestmentOption />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "create",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanCreate"
                    >
                      <AddInvestmentOption />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "view/:id",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanView"
                    >
                      <ViewInvestmentOption />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "edit/:id",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanEdit"
                    >
                      <EditInvestmentOption />
                    </PermissionGuard>
                  ),
                },
              ],
            },
            {
              path: "pensionFund",
              element: <PensionFundContainer />,
              children: [
                {
                  path: "list",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanView"
                    >
                      <PensionFunds />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "view/:id",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanView"
                    >
                      <ViewPensionFund />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "create",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanCreate"
                    >
                      <AddPensionFund />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "edit/:id",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanEdit"
                    >
                      <EditPensionFund />
                    </PermissionGuard>
                  ),
                },
              ],
            },
            {
              path: "suppliers",
              element: <SupplierContainer />,
              children: [
                {
                  path: "list",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanView"
                    >
                      <Suppliers />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "view/:id",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanView"
                    >
                      <ViewSupplier />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "create",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanCreate"
                    >
                      <AddSupplier />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "edit/:id",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanEdit"
                    >
                      <EditSupplier />
                    </PermissionGuard>
                  ),
                },
              ],
            },
            {
              path: "avcplans",
              element: <AvcPlansContainer />,
              children: [
                {
                  path: "list",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanView"
                    >
                      <AvcPlans />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "view/:id",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanView"
                    >
                      <ViewAvcPlans />
                    </PermissionGuard>
                  ),
                },
              ],
            },
            {
              path: "pensionType",
              element: <PensionTypeContainer />,
              children: [
                {
                  path: "list",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanView"
                    >
                      <PensionTypes />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "view/:id",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanView"
                    >
                      <ViewPensionType />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "create",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanCreate"
                    >
                      <AddPensionType />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "edit/:id",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanEdit"
                    >
                      <EditPensionType />
                    </PermissionGuard>
                  ),
                },
              ],
            },
            {
              path: "payPeriod",
              element: <PayPeriodContainer />,
              children: [
                {
                  path: "list",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanView"
                    >
                      <PayPeriod />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "view/:id",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanView"
                    >
                      <ViewPayPeriodDetails />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "create",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanCreate"
                    >
                      <AddPayPeriod />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "edit/:id",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanEdit"
                    >
                      <EditPayPeriod />
                    </PermissionGuard>
                  ),
                },
              ],
            },
            {
              path: "rules",
              element: <RuleMasterContainer />,
              children: [
                {
                  path: "list",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanView"
                    >
                      <RulesMaster />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "create",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanCreate"
                    >
                      <AddRule />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "view/:id",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanView"
                    >
                      <ViewRule />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "edit/:id",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanEdit"
                    >
                      <EditRule />
                    </PermissionGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: "audit-logs",
          children: [
            {
              path: "employers",
              children: [
                {
                  path: "list",
                  element: (
                    <PermissionGuard
                      moduleName="Employers"
                      permissionType="CanView"
                    >
                      <ChangeLog />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "view/:id",
                  element: (
                    <PermissionGuard
                      moduleName="Employers"
                      permissionType="CanView"
                    >
                      <ViewChangeLog />
                    </PermissionGuard>
                  ),
                },
              ],
            },
            {
              path: "employees",
              children: [
                {
                  path: "list",
                  element: (
                    <PermissionGuard
                      moduleName="Employees"
                      permissionType="CanView"
                    >
                      <ChangeLog />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "view/:id",
                  element: (
                    <PermissionGuard
                      moduleName="Employees"
                      permissionType="CanView"
                    >
                      <ViewChangeLog />
                    </PermissionGuard>
                  ),
                },
              ],
            },
            {
              path: "products",
              children: [
                {
                  path: "list",
                  element: (
                    <PermissionGuard
                      moduleName="Products"
                      permissionType="CanView"
                    >
                      <ChangeLog />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "view/:id",
                  element: (
                    <PermissionGuard
                      moduleName="Products"
                      permissionType="CanView"
                    >
                      <ViewChangeLog />
                    </PermissionGuard>
                  ),
                },
              ],
            },
            {
              path: "master-data",
              children: [
                {
                  path: "list",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanView"
                    >
                      <ChangeLog />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "view/:id",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanView"
                    >
                      <ViewChangeLog />
                    </PermissionGuard>
                  ),
                },
              ],
            },
            {
              path: "hct-config",
              children: [
                {
                  path: "list",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanView"
                    >
                      <ChangeLog />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "view/:id",
                  element: (
                    <PermissionGuard
                      moduleName="Master Data"
                      permissionType="CanView"
                    >
                      <ViewChangeLog />
                    </PermissionGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: "cmsPages",
          children: [
            {
              path: "mmm-pages",
              children: [
                {
                  path: "list",
                  element: (
                    <PermissionGuard
                      moduleName="CMS Pages"
                      permissionType="CanView"
                    >
                      <CmsPages />
                    </PermissionGuard>
                  ),
                },
              ],
            },
            {
              path: "product-pages",
              children: [
                {
                  path: "list",
                  element: (
                    <PermissionGuard
                      moduleName="CMS Pages"
                      permissionType="CanView"
                    >
                      <ProductPages />
                    </PermissionGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: "hct-config",
          children: [
            {
              path: "consent",
              element: <LayoutContainer />,
              children: [
                {
                  path: "list",
                  element: (
                    <PermissionGuard
                      moduleName="Consent"
                      permissionType="CanView"
                    >
                      <HealthConsent />
                    </PermissionGuard>
                  ),
                },
              ],
            },
            {
              path: "journey",
              element: <LayoutContainer />,
              children: [
                {
                  path: "list",
                  element: (
                    <PermissionGuard
                      moduleName="Journey"
                      permissionType="CanView"
                    >
                      <JourneyList />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "view/:id",
                  element: (
                    <PermissionGuard
                      moduleName="Journey"
                      permissionType="CanView"
                    >
                      <ViewJourney />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "add",
                  element: (
                    <PermissionGuard
                      moduleName="Journey"
                      permissionType="CanCreate"
                    >
                      <AddJourney />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "edit/:id",
                  element: (
                    <PermissionGuard
                      moduleName="Journey"
                      permissionType="CanEdit"
                    >
                      <EditJourney />
                    </PermissionGuard>
                  ),
                },
              ],
            },
            {
              path: "responses",
              element: <LayoutContainer />,
              children: [
                {
                  path: "list",
                  element: (
                    <PermissionGuard
                      moduleName="Responses"
                      permissionType="CanView"
                    >
                      <ResponsesList />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "view/:id",
                  element: (
                    <PermissionGuard
                      moduleName="Responses"
                      permissionType="CanView"
                    >
                      <ViewResponses />
                    </PermissionGuard>
                  ),
                },
              ],
            },
            {
              path: "smart-engine-products",
              element: <LayoutContainer />,
              children: [
                {
                  path: "list",
                  element: (
                    <PermissionGuard
                      moduleName="Consent"
                      permissionType="CanView"
                    >
                      <SmartEngineProductList />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "createSequence",
                  element: (
                    <PermissionGuard
                      moduleName="Consent"
                      permissionType="CanView"
                    >
                      <AddSequence />
                    </PermissionGuard>
                  ),
                },
                {
                  path: "edit/:id",
                  element: (
                    <PermissionGuard
                      moduleName="Consent"
                      permissionType="CanView"
                    >
                      <UpdateSequence />
                    </PermissionGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: "apps",
          children: [
            {
              path: "profiles",
              children: [
                {
                  path: "user",
                  element: <UserProfile />,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/maintenance",
      element: <CommonLayout />,
      children: [
        {
          path: "404",
          element: <MaintenanceError />,
        },
        {
          path: "500",
          element: <MaintenanceError500 />,
        },
        {
          path: "coming-soon",
          element: <MaintenanceComingSoon />,
        },
      ],
    },
    {
      path: "emails",
      children: [
        {
          path: "unsubscribe",
          element: <EmailUnsubscribe />,
        },
      ],
    },
    {
      path: "*",
      element: <MaintenanceError />,
    },
  ],
};

export default MainRoutes;
